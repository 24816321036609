<template>
  <div class="withdraw">
    <SimpleHeader></SimpleHeader>
    <div class="ml">
      <el-button type="text" class="el-icon-back fs16" @click="$router.go(-1)"> {{ $t(207) }}</el-button>
    </div>
    <div class="fund-box">
      <h3 class="title">{{ $t(189) }}</h3>
      <div class="flex">
        <el-form
          class="flex1"
          ref="form"
          :rules="rules"
          :model="form">
          <div class="item coin-select mb20">
            <div class="label mb5">{{ $t(196) }}</div>
            <img class="curCoinLogo" v-if="coin" :src="coinMsg[coin]?coinMsg[coin].logoUri:''" alt="" style="margin-right: 10px;">
            <el-select v-model="coin" class="coin-input" placeholder="">
              <el-option
                v-for="item in coinList"
                :key="item.coin"
                :value="item.coin"
                :label="item.coin">
                <div class="flex align-center">
                  <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" alt="" style="width: 24px;height:24px;margin-right: 10px;">
                  <span>{{ item.coin }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <div class=" flex align-center label mb5">{{ $t(213) }} <span v-if="walletsOptions.length===0" class="flex1 fs12 danger text-right">{{$t(222)}} <span type="text" class="cursor primary" @click="$router.push({name: 'AddWallet',query:{coin: coin}})">{{ $t(223) }}</span></span></div>
            <el-form-item prop="addressId">
              <el-select v-model="form.addressId" placeholder="">
                <el-option
                  v-for="item in walletsOptions"
                  :key="item.id"
                  :value="item.id"
                  :label="item.address">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item" v-if="curWallet.chain">
            <div class="label mb5">{{ $t(199) }}</div>
            <div class="tab">
              <div class="item active">{{ curWallet.chain }}</div>
            </div>
          </div>
          <div class="item amount mb30">
            <div class="label mb5">{{ $t(214) }}</div>
            <el-form-item prop="amount">
              <el-input v-model="form.amount" :placeholder="$t(215)+' '+availableAmount"></el-input>
            </el-form-item>
            <div class="append">{{ coin }} | <span class="cursor" @click="allClick">{{ $t(216) }}</span></div>
            <div class="fee fs12 color999 text-right" style="margin-top: -10px;">{{ $t(217) }}: {{ configObj.withdrawFee }}%</div>
          </div>
          <div class="item" v-if="configObj.isOpenGlobalVerifyCode==='1'&&configObj.isOpenWithdrawVerifyCode==='1'">
            <div class="label mb5">{{ $t(122) }}</div>
            <el-form-item
              prop="verifyCode">
                <el-input v-model="form.verifyCode">
                  <el-button slot="append" :disabled="text!==sendText" type="text" @click="sendCode">
                    <span :class="{'countDown': sendText !== text}">{{ sendText }}</span>
                  </el-button>
              </el-input>
            </el-form-item>
          </div>
          <div class="item mt20">
            <div class="label mb5">{{ $t(137) }} <span class="fs12 color999">({{ $t(221) }}: 123456)</span></div>
            <el-form-item prop="password">
              <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
          </div>
          <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(177) }}</el-button></div>
        </el-form>
      </div>
    </div>
    <SimpleFooter></SimpleFooter>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import { getWallets, withdraw, getCodeByToken } from '@/api'
import utils from '@/utils/misc'
import {findIndex} from '@/utils/misc'
export default {
  name: 'Withdraw',
  components: { SimpleHeader, SimpleFooter },
  mixins: [],
  data() {
    return {
      coin: '',
      wallets: [],
      curWallet: {},
      loading:false,
      form: {
        addressId: '',
        amount: '',
        password: '',
        verifyCode: '',
      },
      sendText: '',
      text: this.$t('123'),
      timer: null
    }
  },
  watch: {
    walletsOptions(val) {
      if (val && val.length) {
        this.curWallet = val[0]
        this.form.addressId = this.curWallet.id
      } else {
        this.curWallet = {}
         this.form.addressId = ''
      }
    },
    coinList(val) {
      if (val.length) {
        this.coin = val[0].coin
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    coinMsg() {
      return this.$store.state.coins
    },
    configObj() {
      return window.configObj
    },
    coinList() {
      return this.$store.state.assets.assets.SPOT ? this.$store.state.assets.assets.SPOT.coins.filter(item => {
        return item.totalAmount > 0
      }) : []
    },
    chains() {
      return this.coinList.filter(item => item.coin === this.coin)
    },
    availableAmount() {
      for (const item of this.coinList) {
        if (this.coin === item.coin) {
          return item.availableAmount
        }
      }
      return 0
    },
    walletsOptions() {
      return this.wallets.filter(item => item.coin === this.coin)
    },
    rules() {
      return {
        amount:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(218)))
            }
            if (isNaN(value) || +value <= 0 ) {
              return callback(new Error(this.$t(210)))
            }
            callback()
          },
          trigger: 'blur'
        }],
        password:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(110)))
            }
            if (!/^\d{6}$/.test(value)) {
              return callback(new Error(this.$t(220)))
            }
            callback()
          },
          trigger: 'blur'
        }],
        verifyCode:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(124)))
            }
            if (!/^\d{6}$/.test(value)) {
              return callback(new Error(this.$t(125)))
            }
            callback()
          }
        }]
      }
    }
  },
  beforeMount() {
    this.sendText = utils.getLs('sendText') || this.text
    this.setTimer()
    getWallets().then(res => {
      this.wallets = res.addresses || []
    })
    if (this.coinList.length) {
      this.coin = this.coinList[0].coin
    }
  },
  methods: {
    setTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (isNaN(this.sendText)) return
        if (this.sendText <= 1) {
          this.sendText = this.text
          clearInterval(this.timer)
          utils.removeLs('sendText')
        } else {
          this.sendText--
          utils.setLs('sendText', this.sendText)
        }
      }, 1000)
    },
    sendCode() {
      if (this.loading) return
      const obj = {
        scene: 'WITHDRAW',
        type: this.userInfo.mobile? 'MOBILE':'EMAIL'
      }
      this.loading = true
      getCodeByToken(obj).then(() => {
        this.sendText = 60
        this.setTimer()
      }).finally(() => {
        this.loading = false
      })
    },
    allClick() {
      if (!this.availableAmount) return
      this.form.amount = this.availableAmount
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form,amount: +this.form.amount, password: this.form.password}
          if (!obj.verifyCode) {
            obj.verifyCode = '123456'
          }
          withdraw(obj).then(res => {
            if (res.code === 10030009) {
              if (this.$store.state.userInfo.drawRemark) {
                this.showErrorMsg(this.$store.state.userInfo.drawRemark)
              } else {
                this.$store.dispatch('getUserInfo').then(() => {
                  this.showErrorMsg(this.$store.state.userInfo.drawRemark)
                })
              }
            } else {
              this.showSuccessMsg(this.$t(224))
              this.$store.dispatch('getUserAssets')
              this.$router.go(-1)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml{
    width: 600px;
    margin: 0 auto;
  }
  .fund-box{
    color: #1E2329;
    width: 600px;
    border-radius: 8px;
    margin: 5vh auto;
    background: #fff;
    padding: 40px;
    .title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 10px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
    .coin-select{
      position: relative;
      .curCoinLogo{
        position: absolute;
        left: 10px;
        top: 28px;
        height: 24px;
        width: 24px;
        z-index:99;
      }
    }
  }
</style>
<style lang="scss" scoped>
.coin-input {
  .el-input__inner{
    padding-left: 40px;
  }
}
.amount {
  position: relative;
  .append{
    position: absolute;
    right: 20px;
    top: 30px;
    height: 24px;
    line-height: 24px;
    z-index:99;
  }
  .fee{
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
  .el-input__inner{
    padding-right: 80px;
  }
}
</style>